.app-header {
  height: 80px;
  padding: 8px 16px;
  box-shadow: 0 2px 8px #2f636d;
  width: 100%;
  min-width: 1024px;
  display: flex;
  vertical-align: middle;
  z-index: 1001;
}

.app-header #brand {
  height: 48px;
  padding: 0 16px 0 0;
  border-right: 1px solid #f0f0f0;
  margin: auto;
  align-self: center;
  margin-left: 0 !important;
}

.header-logos {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.vertical-divider {
  width: 3px;
  height: 45px;
  background-color: #f0f0f0;
  margin: 0 1rem;
}
